.osta-grid-container {
    margin-top: -2.5rem;
    margin-bottom: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.osta-grid-column {
    padding: 1rem;
}
.osta-card-2024 {
    border-radius: 1.5rem;
    background-color: var(--light-background);
    border: none;
    padding: 2.5rem;
    align-items: center;
    row-gap: 2.5rem;
    height: 100%;
}
.osta-card-title {
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
    padding: 0;
    color: var(--text-primary);
}
.osta-image-container {
    padding: 1.5rem 3rem;
    background-color: var(--white);
    border-radius: 1.5rem;
}
.osta-card-image {
    display: block;
    max-height: 8rem;
    width: 100%;
    height: 100%;
}
.osta-card-text {
    color: var(--text-primary);
    margin: 0;
    padding: 0;
}
.osta-card-description {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1200px) {
    .osta-grid-container {
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width: 768px) {
    .osta-grid-container {
        grid-template-columns: 1fr;
    }
}
