.header {
    font-size: 1.8rem;
}
.container {
    display: flex;
    flex-direction: column;
    padding: 2rem 2.5rem;
    background-color: var(--light-background);
    border-radius: 2rem;
    row-gap: 2rem;
}
.productsContainer {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    margin-top: 1.5rem;
    row-gap: 2rem;
}
.bottomLineContainer {
    font-size: 1rem;
}
.bottomLineContainer h5 {
    font-size: max(1.5em, 18px);
    font-weight: 700;
    color: var(--text-primary);
    margin-bottom: 1.2rem;
}
.cartContainer {
    display: flex;
    column-gap: 1rem;
    justify-content: space-between;
}
.cartItem {
    display: flex;
    column-gap: 0.8rem;
    justify-content: space-between;
}
.shoppingCart {
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    justify-content: center;
    align-items: center;
}
.cartButton {
    padding-left: 2rem;
    padding-right: 2rem;
}
.itemName {
    font-size: max(1.3em, 16px);
    font-weight: 700;
    color: var(--dark-primary);
}
.total {
    color: var(--text-primary);
}
.cartImageContainer {
    position: relative;
    display: flex;
    justify-content: center;
}
.cartImage {
    width: 5rem;
    height: auto;
}
.cartNumber {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    text-align: center;
    background-color: var(--badge-background);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    font-weight: 700;
    font-size: 0.8rem;
    position: absolute;
    top: -1rem;
}
@media screen and (max-width: 1200px) {
    .header {
        font-size: 1.6rem;
    }
    .bottomLineContainer {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 992px) {
    .header {
        font-size: 1.4rem;
    }
    .bottomLineContainer {
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 768px) {
    .cartContainer {
        flex-direction: column;
        row-gap: 2rem;
        align-items: flex-start;
    }
}

@media screen and (max-width: 576px) {
    .header {
        font-size: 1.2rem;
    }
    .container {
        padding: 1.5rem;
        border-radius: 1.5rem;
        row-gap: 1rem;
    }
    .productsContainer {
        margin-top: 1.5rem;
        row-gap: 1.5rem;
        padding: 0;
    }
    .bottomLineContainer {
        font-size: 0.6rem;
    }
}
