.digi-btn {
    background-color: var(--button-background);
    color: #fff;
    border: none;
    border-radius: 2rem;
    padding: 0.63rem 1.25rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.digi-btn:hover {
    background-color: var(--button-hover-color);
}

.digi-btn-secondary {
    background-color: var(--white);
    color: var(--text-primary);
}

.digi-btn-secondary:hover {
    background-color: var(--gray-primary);
}

.digi-btn-emphasized {
    font-weight: 700;
    text-transform: uppercase;
}
