.container {
    padding: var(--container-padding-vertical) var(--container-padding-horizontal);
    max-width: var(--container-max-width);
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 3.5rem;
    margin: auto;
}

.videoContainer {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
}

@media screen and (max-width: 1200px) {
    .container {
        padding: calc(var(--container-padding-vertical) / 2) calc(var(--container-padding-horizontal) / 2);
        row-gap: 1.75rem;
    }
}

@media screen and (max-width: 992px) {
    .container {
        padding: calc(var(--container-padding-vertical) / 3) calc(var(--container-padding-horizontal) / 3);
        row-gap: 1.5rem;
    }
}

@media screen and (max-width: 576px) {
    .container {
        padding: 1rem;
        row-gap: 1rem;
    }
}
