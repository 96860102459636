.header {
    display: flex;
    gap: 1rem;
    font-size: 1rem;
    align-items: baseline;
}
.header * {
    margin: 0;
}
.descriptionContainer {
    display: flex;
    flex-direction: column;
}
.title {
    font-size: 1.8em;
    font-weight: 700;
    color: var(--text-primary);
}
.descriptionContainer h4,
.descriptionContainer h1,
.descriptionContainer h2,
.descriptionContainer h3 {
    font-size: max(1.8em, 18px);
    font-weight: 500;
    color: var(--dark-primary);
}
.descriptionContainer h5 {
    font-size: max(1.5em, 16px);
    font-weight: 500;
    color: var(--dark-primary);
}
.descriptionContainer h6 {
    font-size: max(1.2em, 14px);
    font-weight: 500;
    color: var(--dark-primary);
}
.descriptionContainer p {
    font-size: max(1em, 14px);
    font-weight: 500;
    color: var(--dark-primary);
}

@media screen and (max-width: 1200px) {
    .header {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 992px) {
    .header {
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 576px) {
    .header {
        font-size: 0.6rem;
        flex-direction: column;
    }
}

/* product */
.productContainer * {
    margin: 0;
}
.productContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    color: var(--dark-primary);
}
.productName {
    font-size: 1.2rem;
    font-weight: 700;
}
.boxesContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2.5rem;
}
/* .box {
    height: 0;
    padding-bottom: 100%;
    position: relative;
} */
.boxContent {
    /* position: absolute;
    left: 0;
    top: 0; */
    width: 100%;
    height: 100%;
    padding: 0.8rem;
    border-radius: 2rem;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--dark-primary);
    text-align: center;
    row-gap: 1rem;
    cursor: pointer;
}
.boxContent ul,
.boxContent li {
    text-align: left;
}
.active {
    border-color: var(--text-primary);
    border-width: 3px;
    border-style: solid;
}
.locked {
    background-color: var(--gray-light);
}
.boxContent h1,
.boxContent h2,
.boxContent h3,
.boxContent h4,
.boxContent h5,
.boxContent h6 {
    font-weight: 700;
}
.boxContent a {
    color: var(--text-primary);
    font-weight: 700;
}
.boxContent p {
    font-weight: 500;
    font-size: 1.2rem;
}
.thirdBox {
    row-gap: 1.5rem;
    color: var(--text-primary);
    cursor: pointer;
}
.thirdBox pre *,
.thirdBox pre code {
    font-size: 1rem;
    color: var(--text-primary);
    font-weight: 500;
    font-family: "Poppins";
}
.productImage {
    width: 100%;
    height: auto;
    border-radius: 2rem;
}

@media (max-width: 900px) {
    .boxesContainer {
        grid-template-columns: 1fr;
        row-gap: 1.5rem;
    }
}
