.serviceContainer {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}
.serviceContainer * {
    margin: 0;
}
.tableDropdownContainer {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    justify-content: center;
    color: var(--text-primary);
    font-size: 1.5rem;
    cursor: pointer;
}
.table td {
    padding: 1rem 0.6rem;
    vertical-align: middle;
}
.table .top {
    vertical-align: top;
}
.icon {
    transform: rotate(90deg);
    transition: all 0.3s ease-in-out;
}
.iconOpen {
    transform: rotate(-90deg);
    transition: all 0.3s ease-in-out;
}
.dropDownIcon {
    width: 1.5em;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

/* .dropDownIcon:hover {
    animation: pulse 0.8s infinite;
} */

.description {
    font-size: 1.5rem;
    color: var(--drak-primary);
    text-align: center;
}

.tableOpen {
    max-height: 2400px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}
.tableHidden {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}
.bottomText {
    margin-top: 2rem;
    padding: 0.5rem;
}
.star {
    width: 2em;
    height: auto;
}
@media screen and (max-width: 768px) {
    .tableDropdownContainer {
        font-size: 1.3rem;
    }
    .description {
        font-size: 1.3rem;
    }
    .serviceContainer {
        row-gap: 1.3rem;
    }
    .bottomText {
        font-size: 1rem;
        margin-top: 1.5rem;
    }
}

@keyframes pulse {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(3px);
    }
    100% {
        transform: translateX(0);
    }
}
