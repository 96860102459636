.container {
    background-color: var(--light-background);
}
.item {
    display: flex;
    flex-direction: column;
    row-gap: 1.3rem;
    background-color: var(--light-background);
    padding: 2.5rem;
    justify-content: center;
    align-items: center;
    color: var(--text-primary);
    font-family: "Poppins", sans-serif;
    text-align: center;
}
.item * {
    margin: 0;
}
.title {
    font-size: 1.8rem;
    font-weight: 700;
}
.content {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    font-style: italic;
    max-width: 640px;
}
.author {
    font-size: 1.2rem;
    font-weight: 500;
}
