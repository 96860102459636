.container {
    display: flex;
    column-gap: 0.8em;
    align-items: center;
    color: var(--text-primary);
}
.icon {
    height: 1.5em;
    width: auto;
    display: block;
}
.text {
    font-size: 1em;
}