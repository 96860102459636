.container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    color: var(--text-primary);
    align-items: center;
    max-width: 750px;
    margin: auto;
    text-align: center;
    margin-top: 2rem;
    padding: 0.5rem;
}
.header {
    font-size: 1.8rem;
    font-weight: 700;
}
.message {
    font-size: 1.2rem;
    font-weight: 500;
}
.note {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 500;
    max-width: 500px;
}